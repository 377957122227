import * as React from 'react'

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { Button, Grid, Link, Stack } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import BookButton from '../components/book_button'
import CursiveTypography from '../components/cursive_typography'
import NavBar from '../components/nav_bar'
import SeoHeaders from '../components/seo_headers'
import SocialButtons from '../components/social_buttons'
import LandingFooter from '../sections/landing/footer'

const DELIVERY_URL_BUQUE_INSIGNIA = 'https://www.rappi.cl/restaurantes/900015968-ocean-pacifics-buque-insignia'
const DELIVERY_URL_ESMERALDA = 'https://www.rappi.cl/restaurantes/900015969-ocean-pacifics-esmeralda'

const DeliveryPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        title='Delivery a domicilio'
        description='Llevamos nuestra amplia variedad de platos a tu domicilio a través de Rappi.'
      />
      <NavBar />
      <SocialButtons />
      <BookButton />
      <Stack
        p={3}
        spacing={5}
        minHeight='100%'
        alignItems='center'
        justifyContent='center'
      >
        <StaticImage
          src='../images/delivery/divider.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
        <CursiveTypography
          variant='h1'
          textAlign='center'
        >
          Ahora puedes realizar tus pedidos por Rappi
        </CursiveTypography>
        <Grid
          container
          maxWidth='sm'
          sx={{
            '& > .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 1,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            p={1}
          >
            <Link
              target='_blank'
              rel='noopener noreferrer'
              href={DELIVERY_URL_BUQUE_INSIGNIA}
            >
              <StaticImage
                src='../images/menus/buque-insignia.png'
                alt='Restaurant Buque Insignia'
                placeholder='none'
                quality={90}
              />
            </Link>
            <Button
              target='_blank'
              rel='noopener noreferrer'
              href={DELIVERY_URL_BUQUE_INSIGNIA}
              startIcon={<ShoppingBagIcon />}
              variant='outlined'
              size='large'
            >
              Pedir Ahora
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            p={1}
          >
            <Link
              target='_blank'
              rel='noopener noreferrer'
              href={DELIVERY_URL_ESMERALDA}
            >
              <StaticImage
                src='../images/menus/esmeralda.png'
                alt='Restaurant Esmeralda'
                placeholder='none'
                quality={90}
              />
            </Link>
            <Button
              target='_blank'
              rel='noopener noreferrer'
              href={DELIVERY_URL_ESMERALDA}
              startIcon={<ShoppingBagIcon />}
              variant='outlined'
              size='large'
            >
              Pedir Ahora
            </Button>
          </Grid>
        </Grid>
        <StaticImage
          src='../images/delivery/logo-rappi.png'
          alt='Logo Rappi'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 160 }}
        />
        <StaticImage
          src='../images/delivery/divider.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
      </Stack>
      <LandingFooter />
    </React.Fragment>
  )
}

export default DeliveryPage
